import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import ContactUsDialogProps from '../interfaces/ContactUsDialogProps';

const ContactUsDialog:React.FC<ContactUsDialogProps> = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}  maxWidth="sm" fullWidth>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button onClick={onClose} style={{ float: 'right' }}>
                            X
                        </Button>
                        <h2 style={{marginLeft: "60px"}}>Contact Us</h2>
                    </Grid>
                    <Grid item xs={12} className='text-center'>
                        <p>
                            For questions about your Lansing account, assistance with  <br /> LansingNOW or customer programs, please reach out to us.
                        </p>
                        <br />
                        <p>
                            LansingNOW Helpline: 
                        </p>
                        <p>
                            <a style={{color: "$lansingBlue", fontWeight: "bold"}} href="mailto: helpnow@lansingbp.com">helpnow@lansingbp.com</a>
                        </p>
                        <p>
                            <a style={{color: "$lansingBlue", fontWeight: "bold"}} href="tel:888-631-0085">888-631-0085</a>
                        </p>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ContactUsDialog;