import { useHierarchicalMenu } from 'react-instantsearch';
import React, { useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import CustomHierarchicalMenuProps from '../interfaces/CustomHierarchicalMenuProps';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

type HierarchicalListProps = Pick<
  ReturnType<typeof useHierarchicalMenu>,
  'items' | 'createURL'
> & {
  onNavigate(value: string, isRoot: boolean): void;
} & {
    isRoot: boolean
} & {
    navSearchItemClicked: (navigate: NavigateFunction, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, itemData: any, closeImmediately: boolean) => void;
};

const HierarchicalList: React.FC<HierarchicalListProps> = ({ items, createURL, onNavigate, isRoot, navSearchItemClicked }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    if (items.length === 0) {
      return null;
    }
  
    return (
        <div className={isRoot ? 'ais-HierarchicalMenu' : 'ais-HierarchicalMenu-list--child'}>
            <ul className='productCategoryMenu ais-HierarchicalMenu-list'>
                {
                    items.filter(x => x.value !== "My Most Purchased").map((item: any) => 
                        item.haschildren ? 
                        (
                            <li key={item.value} className={item.isRefined ? "ais-HierarchicalMenu-item ais-HierarchicalMenu-item--selected ais-HierarchicalMenu-item--parent" : "ais-HierarchicalMenu-item"}>
                                <div>
                                    <a href="/#" 
                                        className='ais-HierarchicalMenu-link '
                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            onNavigate(item.value, isRoot);
                                        }}
                                        style={{ color: item.isRefined ? '' : '' }}
                                        onDoubleClick={(event) => navSearchItemClicked(navigate, event, item, true)}
                                        >
                                        <span className='ais-HierarchicalMenu-label'>
                                            {item.label}
                                        </span>
                                        <div className="ais-NavMenu-category-icons">
                                            <i className="gg-chevron"></i>
                                        </div>
                                    </a>
                                    {isRoot && !isMobile ? (
                                        <></>
                                    ) : (
                                        <>
                                            <a  className="ais-HierarchicalMenu-link viewAll" href="/#" 
                                                onClick={(event) => navSearchItemClicked(navigate, event, item, true)}
                                                >
                                                <span className='ais-HierarchicalMenu-label'>View All</span>
                                            </a>
                                        </>
                                    )}

                                </div>
                                {
                                    item.data && (
                                        <HierarchicalList
                                            items={item.data}
                                            onNavigate={onNavigate}
                                            createURL={createURL}
                                            isRoot={false}
                                            navSearchItemClicked={navSearchItemClicked}
                                        />
                                    )
                                }
                            </li>
                        )
                        : (
                            <li key={item.value}  className={item.isRefined ? "ais-HierarchicalMenu-item ais-HierarchicalMenu-item--selected" : "ais-HierarchicalMenu-item"}>
                                <a href="/#" onClick={(event) => navSearchItemClicked(navigate, event, item, true)}>
                                    <span>
                                        {item.label}
                                    </span>
                                    <br />
                                </a>
                                {
                                    item.data && (
                                        <HierarchicalList
                                            items={item.data}
                                            onNavigate={onNavigate}
                                            createURL={createURL}
                                            isRoot={false}
                                            navSearchItemClicked={navSearchItemClicked}
                                        />
                                    )
                                }
                            </li>
                        )
                    )
                }
            </ul>
        </div>
    );
}

const CustomHierarchicalMenu = (data: CustomHierarchicalMenuProps) => {
    const {
        items,
        refine,
        createURL,
    } = useHierarchicalMenu(data);
    
    const navigate = useNavigate();

    const [lvl1SelectedValue, setLvl1SelectedValue] = useState<string | null>(null);

    const NavSearchItemClicked = (navigate: NavigateFunction, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, itemData: any, closeImmediately: boolean) => {
        event.preventDefault();
        event.stopPropagation();

        let selectedItems = itemData.value.split(' > ');
        data.navForceRedirect(navigate, selectedItems[0], selectedItems[1], selectedItems[2], itemData.issmartskip);
    }

    const PerformRefine = (value: string, isRoot: boolean) => {
        let isItemSelected: boolean = false;
        if (isRoot) {
            var selectedItem = items.find(x => x.value === value);
            if (selectedItem) {
                isItemSelected = !selectedItem.isRefined; // isRefined shows the initial state, not the state after refinement is performed
                setLvl1SelectedValue(isItemSelected ? value: null);
                
                data.onSubcategoryOpened(isItemSelected);
            }
        }
        
        refine(value);
    };

    return (
        <>
            <div className="ais-NavMenu-header">Categories</div>
            <div className="ais-NavMenu-subheader">
                <span className="headerText">{lvl1SelectedValue}</span>
                <br />
                <div id="view-all-clickable" className={lvl1SelectedValue == null ? "" : "show"} onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    data.navForceRedirect(navigate, lvl1SelectedValue ?? "", undefined, undefined, false);
                }}>
                    <span>View All</span>
                </div>
            </div>
            <div id='ais-NavMenu-categories'>
                <HierarchicalList
                    items={items}
                    onNavigate={PerformRefine}
                    createURL={createURL}
                    isRoot={true}
                    navSearchItemClicked={NavSearchItemClicked}
                />
            </div>
        </>
    );
}

export default CustomHierarchicalMenu;