import { Button, Typography } from "@mui/material";
import MapComponent from "./mapComponent";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import HomePageProps from "../interfaces/HomePageProps";
import Grid from '@mui/material/Grid';
import onlineOrderingImg from '../img/featureZoneOnlineOrdering.png';
import orderTrackingImg from '../img/featureZoneOrderTracking.png';
import onlineBilLPayImg from '../img/featureZoneBillPay.png';
import rewardsImg from '../img/featureZoneRewards.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import WarehouseSelectorProps from "../interfaces/WarehouseSelectorProps";

const HomepageFeatureZone: React.FC<HomePageProps> = ({ warehouseName, warehouseCode, warehouseAddress,
    warehouseCity, warehouseState, zipCode, warehouseHoursFrom, warehouseHoursTo,
     message, allWarehouses, onUseCurrentLocation }) => { 

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    let carouselElements = [{
        image: onlineOrderingImg,
        text:  "Easy Online Ordering"
    },{
        image: orderTrackingImg,
        text:  "Detailed Order Tracking"
    },{
        image:  onlineBilLPayImg,
        text:  "Seamless Bill Pay"
    },{
        image: rewardsImg,
        text: "Auto-Submit Manufacturer Rewards"
    }];

    let branchClosedMessage: string = "Branch Currently Closed.";
    var branchOpenMessage: string = "OPEN NOW!";
    let messageArray: string[] = [];
    if (message !== null && message.includes(branchClosedMessage))
        messageArray = [ branchClosedMessage, message.slice(branchClosedMessage.length)];
    else if (message !== null && message.includes(branchOpenMessage))
        messageArray = [ branchOpenMessage, message.slice(branchOpenMessage.length)];
    else if (message !== null)
        messageArray = [message];

    let filteredWarehouses: WarehouseSelectorProps[] = allWarehouses.filter(x => x.warehouseCode === warehouseCode);

    return (
        <div className="homepageFeatureZoneContainer">
        <Grid container direction="row" spacing={2} sx={{ justifyContent: "center", alignItems: "stretch",p:1}}>
            <Grid item xs={12} lg={4} className="grid-1">
                <div className="mapHolder">
                    <div className="branch">
                        <div className="branchText">
                            <h1 className="m-0">Powered By Your Local <br/><span className="branchTextNormal">{warehouseName}</span><br/> Branch</h1>
                            <div style={{lineHeight:"26px"}}>
                                {warehouseAddress}<br />
                                {warehouseCity}, {warehouseState}, {zipCode}<br /><br />
                                <p>
                                    {messageArray.map((x, index) => 
                                        <span key={x}>
                                            {x}
                                            {index !== messageArray.length - 1 && <br />}
                                        </span>
                                    )}
                                    <br/>
                                    <b>{warehouseHoursFrom} - {warehouseHoursTo}</b>
                                </p>
                                <Button sx={{color:"white", p:0, mt:2, textAlign:'left', textTransform: 'capitalize'}} onClick={onUseCurrentLocation}><MyLocationIcon fontSize="small" sx={{mr:1}}/> Use My Current Location</Button>
                            </div>
                        </div>
                    </div>  
                    {filteredWarehouses == null 
                        ? <></> 
                        : <MapComponent 
                            mapKey="BRANCH_MAP_HOMEPAGE" 
                            selectedWarehouseCode={warehouseCode} 
                            warehouses={filteredWarehouses} 
                            onMapLocationSelected={() => null} 
                            longitudeCenterOffset={isMobile ? -0.26 : -0.2}
                            disableControls={true} /> 
                    }
                </div>

            </Grid>
            <Grid item xs={12} lg={6} className="grid-2">
                <Carousel
                    autoPlay={true}
                    interval={5000}
                    className="carouselContainer"
                    animation={"slide"}
                    duration={1000}
                    navButtonsAlwaysInvisible={true}
                    swipe={true}>       
                        <div className="slide">
                            <h1>Supporting Your Business,<br/>One Job at a Time</h1>
                            <h2>Seamlessly price and place orders, 24/7, with the dedicated support of your local Lansing team.</h2>
                            <Link className="whiteButton mt-1" to="/gallery?query=">Shop Now</Link>
                        </div>                     
                </Carousel>                
            </Grid>
            <Grid item xs={12} lg={2} className="grid-3">
                <div className="carouselContainerSmall">
                    <Carousel
                        autoPlay={true}
                        interval={5000}
                        animation={"slide"}
                        duration={1000}
                        navButtonsAlwaysInvisible={true}
                        swipe={true}>
                        {
                            carouselElements.map((element, index) =>         
                                <div key={element.image}>
                                    <h2>{element.text}</h2>
                                    <div style={{minHeight:"217px"}}>
                                        <img src={element.image}  alt="Homepage Feature Zone" />
                                    </div>
                                </div> 
                            )
                        }
                    </Carousel>
                    <div>
                        <Link className="whiteButton my-1" to={process.env.REACT_APP_WEB_URL + "/quicklogin"}>Log In</Link>
                        <Typography>Not Registered?<br/><Link style={{color:"#353535"}} to={process.env.REACT_APP_WEB_URL + "/register"}>Create Account</Link></Typography>
                    </div>
                </div>
            </Grid>
        </Grid>
        
        </div>
    );
}

export default HomepageFeatureZone;
