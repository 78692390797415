import { Box, Skeleton, Typography } from "@mui/material";
import ProductSpecificationTabsProps from "../interfaces/ProductSpecificationTabsProps";

const ProductSpecificationFlat:React.FC<ProductSpecificationTabsProps> = ({ brand, aboutText, embeddedVideo, specifications, documents, loading }) => {    

    let aboutVisible = (aboutText !== null && aboutText !== "") || (embeddedVideo !== null && embeddedVideo !== "");
    let specificationsVisible = specifications.length > 0;
    let documentsVisible = documents.length > 0;


    return (
        <Box sx={{ width: '100%', borderTop: 1, borderColor: 'divider', mt:8, pt:5, mb:2  }}>
            {aboutVisible ? (
                <Box>
                    {loading ? 
                    <Skeleton variant="rectangular" animation="wave" height={80} /> : 
                    <>
                        <span className="flatProductSpecificationHeader">About</span>
                        <div className="content" dangerouslySetInnerHTML={{__html: aboutText ?? ""}}></div>
                        <div dangerouslySetInnerHTML={{__html: embeddedVideo ?? ""}}></div>
                    </>
                    } 
                </Box>
            ) : <></>}
            {specificationsVisible ? (
                <Box>
                    {loading ? <Skeleton variant="rectangular" animation="wave" height={80} /> : 
                    <>
                        <span className="flatProductSpecificationHeader">Specifications</span>
                        {
                            specifications.map(specification => (
                                <div key={specification.name}>
                                        <span>{specification.name}: </span>
                                        <b>{specification.value}</b>
                                </div>
                            ))
                        }        
                    </>}                                
                </Box>
            ) : <></>}
            {documentsVisible ? (
                <Box>
                    {loading ? <Skeleton variant="rectangular"animation="wave" width={"100%"} height={80} /> :
                        <>
                            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', mb: 3  }}>
                                <span className="flatProductSpecificationHeader"  style={{ width: '100%' }}>Documents</span>
                            </Box>
                            {
                                documents.map(document => (
                                    <div key={document.fileName}className='tabSpecificationList'>
                                        <a href={process.env.REACT_APP_API_URL + "/GetProductAttachment?id=" + document.attachmentId} target='_blank' rel="noreferrer">{document.fileName}</a>
                                    </div>
                                ))
                            }
                        </>
                    }
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:"20px"}}>
                        {loading ? <Skeleton /> : <>To read PDF files, you need the Adobe Acrobat Reader 6.0 or higher. <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">Click here</a> to download it for free from Adobe's site.</>}
                    </Typography>
                </Box>
            ) : <></>}
        </Box>
    );
} 

export default ProductSpecificationFlat;