import React, { useState } from 'react';
import ProductGalleryImageProps from '../interfaces/ProductGalleryImageProps';

const ProductGalleryImage: React.FC<ProductGalleryImageProps> = ({ src, alt, fallback, mainPictureOverlay }) => {
    const [imgSrc, setImgSrc] = useState(src);

    const handleError = (event: any) => {
        console.error("Product gallery image not loaded" + src);
        setImgSrc(fallback);
    };

    return <img src={imgSrc} 
                alt={alt} 
                onError={handleError}
                style={{ maxWidth: '100%', maxHeight:'100%', width: "auto", height: "auto", objectFit:'contain' ?? "", backgroundColor: mainPictureOverlay ?? "" }} />;
};

export default ProductGalleryImage;