import Grid from '@mui/material/Grid';
import React from 'react';
import HomePageCustomerProgramProp from '../interfaces/HomePageCustomerProgramProp'
import { Link } from 'react-router-dom';

const HomepageCustomerProgram: React.FC<HomePageCustomerProgramProp> = ({ programTitle, programSubtitle, programButtonText, programUrl, programImage, overlayClassName }) => {
    return (
        <Grid item xs={12} lg={6}className='homepageCustomerProgram'>
            <img src={programImage} alt={programTitle + " Thumb"}></img>
            <div className={overlayClassName == null ? "homepageCustomerProgramOverlay" : overlayClassName + " homepageCustomerProgramOverlay"}></div>
            <div>
                <h1>{programTitle}</h1>
                <p>{programSubtitle}</p>
                <Link className='whiteButton' to={programUrl}>{programButtonText}</Link>
            </div>
        </Grid>
    );
};

export default HomepageCustomerProgram;