import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import WarehouseDialogProps from '../interfaces/WarehouseDialogProps';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Typography, Grid } from '@mui/material';
import axios from 'axios';
import  { useState, useEffect  } from 'react';
import WarehouseSelectorProps from '../interfaces/WarehouseSelectorProps';
import MapComponent from './mapComponent';

const WarehouseDialog: React.FC<WarehouseDialogProps> = ({ open, onClose, warehouseCode, warehouseName, 
    warehouseFullAddress, onWarehouseSelected }) => {
    let [allWarehouses, setAllWarehouses] = useState<WarehouseSelectorProps[]>([]);
    let [otherBranches, setOtherBranches] = useState<GridRowsProp>([]);
    
    const handleWarehouseSelect = (newWarehouseCode: string) => {
        onWarehouseSelected(newWarehouseCode);
        onClose();
    };

    const handleMapLocationSelected = (latitude: number, longitude: number) => {
        if (open && warehouseCode != null) {
            axios.get(process.env.REACT_APP_API_URL + "/GetAllBranchesOrderedByLocation?latitude=" + latitude + "&longitude=" + longitude)
            .then(response => {
                const warehouses = response.data.warehouses;

                const filteredBranches = warehouses
                    .filter((x: WarehouseSelectorProps) => x.warehouseCode !== warehouseCode)
                    .map((x: WarehouseSelectorProps) => ({ 
                        id: x.warehouseCode, 
                        name: x.warehouseName, 
                        fullAddress: x.warehouseFullAddress
                    }));
                setOtherBranches(filteredBranches);
            })
            .catch(error => {
                console.error(error);
            });
        }
    };
    
    useEffect(() => {
        if (open && warehouseCode != null) {
            axios.get(process.env.REACT_APP_API_URL + "/GetAllBranches?selectedWarehouseCode=" + warehouseCode)
            .then(response => {
                const warehouses = response.data.warehouses;
                setAllWarehouses(warehouses);

                const filteredBranches = warehouses
                    .filter((x: WarehouseSelectorProps) => x.warehouseCode !== warehouseCode)
                    .map((x: WarehouseSelectorProps) => ({ 
                        id: x.warehouseCode, 
                        name: x.warehouseName, 
                        fullAddress: x.warehouseFullAddress
                    }));
                setOtherBranches(filteredBranches);

                console.info(filteredBranches);
            })
            .catch(error => {
                console.error(error);
            });
        }
    }, [open, warehouseCode]);

    const currentBranchColumns: GridColDef[] = [
        { field: 'name', headerName: 'Name', type: "string", flex: 0.3 },
        { field: 'fullAddress', headerName: 'Address', type: "string", flex: 0.5, renderCell: (params) => {
            var paramsArray: string[] = params.value.split("\n");
            return (
                <div key={params.value}  style={{ whiteSpace: 'pre-line' }}>
                    {paramsArray.map(param => (
                        <Typography key={param}>{param}</Typography>
                    ))}
                </div>
            )
        }},
        { field: 'options', headerName: 'Options', flex: 0.2, renderCell: (params) => (
            <Button disabled={true}>
                Selected
            </Button>
        )}
    ];

    const otherBranchesColumns: GridColDef[] = [
        { field: 'name', headerName: 'Name', type: "string", flex: 0.3, },
        { field: 'fullAddress', headerName: 'Address', type: "string", flex: 0.5, renderCell: (params) => {
            var paramsArray: string[] = params.value.split("\n");
            return (
                <div key={params.value} style={{ whiteSpace: 'pre-line' }}>
                    {paramsArray.map(param => (
                        <Typography key={param}>{param}</Typography>
                    ))}
                </div>
            )            
        }},
        { field: 'options', headerName: 'Options', flex: 0.2, renderCell: (params) => (
            <Button className='blueButtonOutline' onClick={() => 
                    handleWarehouseSelect(params.row.id)
                }>
                Select
            </Button>
        )}
    ];

    const currentBranch: GridRowsProp = [
        { id: warehouseCode, name: warehouseName, fullAddress: warehouseFullAddress }
    ];

    return (
        <Dialog open={open} onClose={onClose}  maxWidth="xl" fullWidth>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button onClick={onClose} style={{ float: 'right' }}>
                            X
                        </Button>
                        <h2 style={{marginBottom:0}}>YOUR BRANCH</h2>
                        <p><b><i>You will see inventory based on the selected branch</i></b></p>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container>
                            <Grid item xs={12}><h4 style={{marginTop:0}}>Current Branch</h4></Grid>
                            <Grid item xs={12}>
                                <DataGrid
                                    rows={currentBranch} 
                                    columns={currentBranchColumns} 
                                    hideFooter={true} 
                                    disableColumnSorting={true} 
                                    disableColumnFilter={true} 
                                    disableColumnMenu={true}
                                    rowSelection={false} />                                
                            </Grid>
                            <Grid item xs={12}>
                                <h4>Your Other Branches</h4>
                            </Grid>
                            <Grid item xs={12}>
                                <DataGrid
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 5, page: 0 },
                                        },
                                    }} 
                                    pageSizeOptions={[5, 10, 25]} 
                                    rows={otherBranches} 
                                    columns={otherBranchesColumns} 
                                    disableColumnSorting={true} 
                                    disableColumnFilter={true} 
                                    disableColumnMenu={true}
                                    rowSelection={false} 
                                    autoHeight/>                                
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item md={6} xs={12} sx={{mb:10}}>
                        <MapComponent 
                            mapKey="BRANCH_MAP_WAREHOUSE_DIALOG" 
                            selectedWarehouseCode={warehouseCode} 
                            warehouses={allWarehouses} 
                            longitudeCenterOffset={0} 
                            onMapLocationSelected={handleMapLocationSelected}
                            disableControls={false} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default WarehouseDialog;