import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

const HomepageProductTemplate = () => {
    return (
        <Container maxWidth="lg" sx={{my:10}}>
            <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent="flex-start" alignItems="center" spacing={2} >
                <h2 className='lansingDarkColor'>Build custom lists for each job, to make re-ordering a breeze</h2>
                <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin"} className='blueButton'>PRODUCT TEMPLATES &raquo;</Link>
            </Stack>
        </Container>
    );
}

export default HomepageProductTemplate;