/*
import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import WarehouseSelectorProps from "../interfaces/WarehouseSelectorProps";
import MapComponentProps from "../interfaces/MapComponentProps";
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MyLocationIcon from '@mui/icons-material/MyLocation';


const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  fullscreenControl: true
};

const MapComponent: React.FC<MapComponentProps> = ({ warehouses, selectedWarehouseCode, longitudeCenterOffset, onMapLocationSelected }) => {
  const [selectedWarehouseOnMap, setSelectedWarehouseOnMap] = useState<WarehouseSelectorProps | null>(null);
  const [selectedLatitude, setSelectedLatitude] = useState<number>(0);
  const [selectedLongitude, setSelectedLongitude] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    setSelectedLatitude(warehouses.find(x => x.warehouseCode === selectedWarehouseCode)?.latitude || 0);
    setSelectedLongitude(warehouses.find(x => x.warehouseCode === selectedWarehouseCode)?.longitude || 0);
  }, [warehouses, selectedWarehouseCode]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  const blueIcon = {
    url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
    scaledSize: new window.google.maps.Size(40, 40), // scale size if needed
  };

  const OnUseCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function(res) {
      setSelectedLatitude(res.coords.latitude);
      setSelectedLongitude(res.coords.longitude);

      onMapLocationSelected(res.coords.latitude, res.coords.longitude);
    });
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    axios
      .get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address: searchQuery,
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        },
      })
      .then((response) => {
        console.info(response);
        const location = response.data.results[0]?.geometry.location;
        if (location) {
          onMapLocationSelected(location.lat, location.lng);
          setSelectedLatitude(location.lat);
          setSelectedLongitude(location.lng);
        }
      })
      .catch((error) => {
        console.error("Error finding location: ", error);
      });
  };
  const handleClear = () => {
    setSearchQuery('');
  };
  return (
    <>
      <div>
        <TextField 
          size="small"
          variant="outlined"
          type="text"
          value={searchQuery}
          className="mapSearchInput"
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleSearchKeyDown}
          placeholder="Enter city, state, or zip code"
          style={{ marginRight: "10px" }}
        />
        <Button className="blueButtonOutline mapSearchButton" sx={{mr:1}} onClick={handleSearch}>Search</Button>
        <Button className="blueButtonOutline mapSearchButton" onClick={handleClear}>Clear</Button>
      </div>
      <div>
        <Button size="small" style={{fontSize:"75%"}} variant="text" className="mapUseCurrentLocation" onClick={OnUseCurrentLocation}><MyLocationIcon fontSize="small" sx={{mr:1}}/> Use My Current Location</Button>
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={{
          lat: selectedLatitude,
          lng: selectedLongitude + longitudeCenterOffset,
        }}
        options={options}
      >
        {warehouses.map((warehouse) => (
          <Marker
            key={warehouse.warehouseCode}
            position={{
              lat: warehouse.latitude ?? 0,
              lng: warehouse.longitude ?? 0,
            }}
            icon={warehouse.warehouseCode === selectedWarehouseCode ? blueIcon : undefined}
            onClick={() => {
              setSelectedWarehouseOnMap(warehouse);
            }}
          />
        ))}

        {selectedWarehouseOnMap && (
          <InfoWindow
            position={{
              lat: selectedWarehouseOnMap.latitude ?? 0,
              lng: selectedWarehouseOnMap.longitude ?? 0,
            }}
            onCloseClick={() => {
              setSelectedWarehouseOnMap(null);
            }}
          >
            <div>
              <h2>{selectedWarehouseOnMap.warehouseName}</h2>
              <p>{selectedWarehouseOnMap.warehouseAddress}</p>
              <p>{selectedWarehouseOnMap.zipCode}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );
};

export default MapComponent;
*/

import React, { useEffect, useState } from "react";
import WarehouseSelectorProps from "../interfaces/WarehouseSelectorProps";
import MapComponentProps from "../interfaces/MapComponentProps";
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { AdvancedMarker, APIProvider, InfoWindow, Map } from '@vis.gl/react-google-maps';
import bluePinImg from '../img/pin-blue.png';
import grayPinImg from '../img/pin-gray.png';

const MapComponent: React.FC<MapComponentProps> = ({ mapKey, warehouses, selectedWarehouseCode, longitudeCenterOffset, 
  disableControls, onMapLocationSelected }) => {
  const [selectedWarehouseOnMap, setSelectedWarehouseOnMap] = useState<WarehouseSelectorProps | null>(null);
  const [selectedLatitude, setSelectedLatitude] = useState<number | null>(null);
  const [selectedLongitude, setSelectedLongitude] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    let lat = warehouses.find(x => x.warehouseCode === selectedWarehouseCode)?.latitude;
    let lng = warehouses.find(x => x.warehouseCode === selectedWarehouseCode)?.longitude;

    if (lat != null)
      setSelectedLatitude(lat);

    if (lng != null)
      setSelectedLongitude(lng);
  }, [warehouses, selectedWarehouseCode]);

  const OnUseCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function(res) {
      setSelectedLatitude(res.coords.latitude);
      setSelectedLongitude(res.coords.longitude);

      onMapLocationSelected(res.coords.latitude, res.coords.longitude);
    });
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (!searchQuery)
      return;

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address: searchQuery }, (results, status) => {
      if (status === 'OK' && results !== null && results[0]) {
        const location = results[0]?.geometry.location;
        if (location) {
          onMapLocationSelected(location.lat(), location.lng());
          setSelectedLatitude(location.lat());
          setSelectedLongitude(location.lng());
        }
      } else {
        console.error("Error finding location. ");
      }
    });
  };
  const handleClear = () => {
    setSearchQuery('');
  };

  return selectedWarehouseCode == null || selectedLatitude == null || selectedLongitude == null ? <></> : (
    <>
      <div>
        <TextField 
          size="small"
          variant="outlined"
          type="text"
          value={searchQuery}
          className="mapSearchInput"
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleSearchKeyDown}
          placeholder="Enter city, state, or zip code"
          style={{ marginRight: "10px" }}
        />
        <Button className="blueButtonOutline mapSearchButton" sx={{mr:1, px:5}} onClick={handleSearch}>Search</Button>
        <Button className="blueButtonOutline mapSearchButton" sx={{px:5}} onClick={handleClear}>Clear</Button>
      </div>
      <div>
        <Button size="small" sx={{my:1}} style={{fontSize:"75%"}} variant="text" className="mapUseCurrentLocation" onClick={OnUseCurrentLocation}><MyLocationIcon fontSize="small" sx={{mr:1}}/> Use My Current Location</Button>
      </div>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}>
        <Map
          key={mapKey + selectedLatitude + selectedLongitude }
          mapId={mapKey}
          defaultZoom={10}
          defaultCenter={{ lat: selectedLatitude, lng: selectedLongitude + longitudeCenterOffset }}
          disableDefaultUI={disableControls}
          zoomControl={!disableControls}
          fullscreenControl={!disableControls}
          disableDoubleClickZoom={disableControls}
          gestureHandling={disableControls ? "none" : null}
          renderingType='UNINITIALIZED'
          style={{ width: '100%', minHeight: '350px' }}
          >
                {warehouses.map((warehouse: WarehouseSelectorProps) => (
                  <AdvancedMarker
                    key={warehouse.warehouseCode}
                    position={{lat: warehouse.latitude ?? 0, lng: warehouse.longitude ?? 0}}
                    onClick={() => {
                      if (!disableControls)
                        setSelectedWarehouseOnMap(warehouse);
                    }}>
                      <img
                        src={warehouse.warehouseCode === selectedWarehouseCode ? bluePinImg : grayPinImg}
                        alt="Map Pin"
                        style={{ width: "50px", height: "50px", cursor: disableControls ? "default" : "pointer" }}
                      />                      
                  </AdvancedMarker>
                ))}
            
            {selectedWarehouseOnMap && (
              <InfoWindow
                position={{
                  lat: selectedWarehouseOnMap.latitude ?? 0,
                  lng: selectedWarehouseOnMap.longitude ?? 0,
                }}
                onCloseClick={() => {
                  setSelectedWarehouseOnMap(null);
                }}
              >
                <div>
                  <h2>{selectedWarehouseOnMap.warehouseName}</h2>
                  <p>{selectedWarehouseOnMap.warehouseFullAddress}</p>
                </div>
              </InfoWindow>
            )}
        </Map>
      </APIProvider>
    </>
  );
};

export default MapComponent;
