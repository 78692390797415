import HeaderWarningProps from "../interfaces/HeaderWarningProps";

const HeaderWarning: React.FC<HeaderWarningProps> = ({ message }) => {
    let headerStyle = {  
        color: "#fff",
        textAlign: "center" as const,
        height: "35px",
        lineHeight: "30px",
        fontWeight: "bold",
        backgroundColor: "#fbb040"
    }

    return message == null ? <></> : <div style={headerStyle}>{message}</div>
}

export default HeaderWarning;