import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import ContactUsDialog from './contactUsDialog';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color:"#54afe2",
    fontFamily:"Montserrat",
    fontWeight:"600",
    '&:hover': {
        color: blue[700],
    },
}));

const QuickLinks = () => {
    const [openContactUsDialog, setOpenContactUsDialog] = useState(false);

    const handleContactUsOpen = () => {
        setOpenContactUsDialog(true);
    };

    const handleContactUsClose = () => {
        setOpenContactUsDialog(false);
    };

    return (
        <>
            <Grid container spacing={2} sx={{my:5}}>
                <Grid item xs={12}>
                    <h2 className="lansingDarkColor m-0">Quick Links</h2>
                </Grid>
                <Grid item md={3}>
                    <div>
                        <ColorButton variant="text" href={process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=%2Fprograms%2Fclearwin"}>Clearwin »</ColorButton>
                    </div>
                    <div>
                        <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin"}>
                            <ColorButton variant="text">Product Templates »</ColorButton>
                        </Link>
                    </div>
                    <div>
                        <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=%2Fprograms%2Flansinggreen"}>
                            <ColorButton variant="text">Lansing Green Financing »</ColorButton>
                        </Link>
                    </div>
                    <div>
                        <ColorButton variant="text" href="https://lansingbp.com/locations/">Locations »</ColorButton>
                    </div>
                </Grid>
                <Grid item  md={3}>
                    <div>
                        <Link to={"/catalog"}>
                            <ColorButton variant="text">Shop Products »</ColorButton>
                        </Link>
                    </div>
                    <div>
                        <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin"}>
                            <ColorButton variant="text">My Account »</ColorButton>
                        </Link>
                    </div>
                    <div>
                        <ColorButton variant="text" className='linkButtons' onClick={handleContactUsOpen}>Contact »</ColorButton>

                        <ContactUsDialog open={openContactUsDialog} onClose={handleContactUsClose} />
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default QuickLinks;