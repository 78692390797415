import {
  BrowserRouter as Router, 
  Routes,
  Route
} from "react-router-dom";
import {Box} from '@mui/material';

import ProductGallery from './components/productGallery';
import Footer from './components/footer';
import './assets/css/App.css';
import MainMenu from './components/mainMenu';
import Homepage from './components/homepage';
import WarehouseBanner from './components/warehouseBanner';
import axios from 'axios';
import  { useState  } from 'react';
import ScrollTop from './components/scrollTop';
import moment from 'moment';
import Product from "./components/product";
import Catalog from "./components/catalog";
import HeaderWarning from "./components/headerWarning";
import WarehouseSelectorProps from "./interfaces/WarehouseSelectorProps";
import FaviconSetter from "./components/faviconSetter";
import TagManager from "react-gtm-module";

const App = () => {  

  // Google Tag Manager Initialization
  if (process.env.REACT_APP_GTM_KEY) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_KEY
    }

    TagManager.initialize(tagManagerArgs);
  }

  let [warehouseName, setWarehouseName] = useState<string | null>(null);
  let [warehouseCode, setWarehouseCode] = useState<string | null>(null);
  let [warehouseIsNEDivision, setWarehouseIsNEDivision] = useState<boolean>(false);
  let [warehouseFullAddress, setWarehouseFullAddress] = useState<string | null>(null);
  let [warehouseAddress, setWarehouseAddress] = useState<string | null>(null);
  let [warehouseCity, setWarehouseCity] = useState<string | null>(null);
  let [warehouseState, setWarehouseState] = useState<string | null>(null);
  let [warehouseZip, setWarehouseZip] = useState<string | null>(null);
  let [warehouseHoursFrom, setWarehouseHoursFrom] = useState<string | null>(null);
  let [warehouseHoursTo, setWarehouseHoursTo] = useState<string | null>(null);
  let [warehouseMessage, setWarehouseMessage] = useState<string | null>(null);
  let [openWarehouseDialogRequestedAt, setOpenWarehouseDialogRequestedAt] = useState<number | null>(null);
  let [allWarehouses, setAllWarehouses] = useState<WarehouseSelectorProps[]>([]);
  let [globalMesage, setGlobalMesage] = useState<string | null>(null);

  if (warehouseCode == null) {
    axios.get(process.env.REACT_APP_API_URL + "/GetNearestBranch")
    .then(response => {
        handleWarehouseChange(response.data.warehouseCode);
    })
    .catch(error => {
      console.error(error);
    });
  }
  
  const handleWarehouseChange = (newWarehouseCode: string) => {
      if (newWarehouseCode !== null) {
        axios.get(process.env.REACT_APP_API_URL + "/GetBranchInfo", {
          params: {
            warehouseCode: newWarehouseCode,
            clientUtcOffsetInMinutes: moment().utcOffset()
          }
        })
        .then(response => {
          setWarehouseCode(newWarehouseCode);
          
          let warehouses: WarehouseSelectorProps[] = response.data.warehouses;
          let warehouse: WarehouseSelectorProps | undefined = warehouses.find(x => x.warehouseCode === newWarehouseCode);
          
          setAllWarehouses(warehouses);
          setWarehouseName(warehouse?.warehouseName ?? "");
          setWarehouseFullAddress(warehouse?.warehouseFullAddress ?? "");
          setWarehouseAddress(warehouse?.warehouseAddress ?? "");
          setWarehouseCity(warehouse?.warehouseCity ?? "");
          setWarehouseState(warehouse?.warehouseState ?? "");
          setWarehouseZip(warehouse?.zipCode ?? "");
          setWarehouseHoursFrom(response.data.hoursFrom);
          setWarehouseHoursTo(response.data.hoursTo);
          setWarehouseIsNEDivision(response.data.isNEDivision)
          setWarehouseMessage(response.data.branchMessage);
          setGlobalMesage(response.data.globalMessage);
        })
        .catch(error => {
          console.error(error);
        });
      }
  };

  const OnHomepageUseCurrentLocation = () => {
    setOpenWarehouseDialogRequestedAt(Date.now());
  };

  return (
    <Router basename={"/" + process.env.REACT_APP_SUBDIRECTORY}>
      <ScrollTop />
      <FaviconSetter />
      <HeaderWarning message={globalMesage} />
      <Box  sx={{
        width: '100%',
        bgcolor: 'white',
        '@media (max-width: 1199px)': {
          position: 'fixed',
          top: 0,
          zIndex: 1000,
        },
        '@media (min-width: 1199px)': {
          marginTop:1
        },
        
        
      }}>
        <WarehouseBanner 
            warehouseName={warehouseName} 
            warehouseCode={warehouseCode} 
            warehouseFullAddress={warehouseFullAddress} 
            warehouseAddress={warehouseAddress}
            warehouseHoursFrom={warehouseHoursFrom} 
            warehouseHoursTo={warehouseHoursTo} 
            message={warehouseMessage}
            openRequestedAt={openWarehouseDialogRequestedAt}
            onWarehouseSelected={handleWarehouseChange} />
        <MainMenu warehouseName={warehouseName} warehouseCode={warehouseCode} isNEDivision={warehouseIsNEDivision} />
      </Box>
      <Routes>
        <Route path="/" element={<Homepage
            warehouseName={warehouseName} 
            warehouseCode={warehouseCode} 
            warehouseFullAddress={warehouseFullAddress} 
            warehouseAddress={warehouseAddress}
            warehouseCity={warehouseCity}
            warehouseState={warehouseState}
            zipCode={warehouseZip}
            warehouseHoursFrom={warehouseHoursFrom} 
            warehouseHoursTo={warehouseHoursTo} 
            message={warehouseMessage}
            isNEDivision={warehouseIsNEDivision}
            allWarehouses={allWarehouses}
            onUseCurrentLocation={OnHomepageUseCurrentLocation} />} />
        <Route path="/gallery" element={<ProductGallery warehouseName={warehouseName} warehouseCode={warehouseCode} />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path='/product' element={<Product warehouseName={warehouseName}  warehouseCode={warehouseCode} />} />
      </Routes>
      <Footer />
      <div className="responsiveMarker"></div>
    </Router>
  );
}

export default App;
