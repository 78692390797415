import HomePageCategories from "./homepageCategories"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "./pageTitle";

const Catalog = () => {
    
    PageTitle("Catalog");   // Set page title
    
    return (
        <Container maxWidth="lg" sx={{my:5}}>
            <h1>Categories</h1>
            <Grid container spacing={1}>
                <HomePageCategories />
        </Grid>
        </Container>
    );
};

export default Catalog;