import HomePageCustomerProgramProp from "../interfaces/HomePageCustomerProgramProp";
import HomepageCustomerProgram from "./homepageCustomerProgram";
import Grid from '@mui/material/Grid';
import proZoneImg from '../img/program-prozone.jpeg';
import proBucksImg from '../img/program-probucks.jpeg';
import savrImg from '../img/program-savr.jpeg';
import marketingImg from '../img/program-marketing.jpeg';
import greenImg from '../img/program-green-financing.jpeg';
import clearwinImg from '../img/program-clearwin.jpeg';
import HomepageCustomerProgramsProps from "../interfaces/HomepageCustomerProgramsProps";

const HomepageCustomerPrograms:React.FC<HomepageCustomerProgramsProps> = ({ isNEDivision, warehouseCode }) => {

  var quickLoginUrl = process.env.REACT_APP_WEB_URL + "/quicklogin";

  let impactRewardsBranches: string[] = (process.env.REACT_APP_IMPACT_REWARDS_BRANCHES ?? "").split(',').map(item => item.toLowerCase());
  let hasImpactRewards: boolean = impactRewardsBranches.includes((warehouseCode ?? "").toLowerCase());

     
  let customerPrograms:HomePageCustomerProgramProp[] = [
    {
      programTitle: "ProZone",
      programSubtitle: "Let us generate leads and help you drive your business forward with ProZone.",
      programButtonText: "Sign Up for ProZone »",
      programUrl: quickLoginUrl + "?returnUrl=%2Fprograms%2Fprozone",
      programImage: proZoneImg,
      overlayClassName: null
    },
    {
      programTitle: hasImpactRewards ? "Impact Rewards" : "ProBucks", 
      programSubtitle: "Join our loyalty program that gives back. The more you spend, the more you save.",
      programButtonText: hasImpactRewards ? "Join Impact Rewards »" : "Join ProBucks »",
      programUrl: hasImpactRewards ? quickLoginUrl + "?returnUrl=%2Fprograms%2Fimpactrewards" : quickLoginUrl + "?returnUrl=%2Fprograms%2Fprobucks",
      programImage: proBucksImg,
      overlayClassName: null
    },
    {
      programTitle: "SAVR",
      programSubtitle: "Let us help you manage manufacturer rebates and rewards.",
      programButtonText: "Explore SAVR »",
      programUrl: quickLoginUrl + "?returnUrl=%2Fprograms%2Fsavr",
      programImage: savrImg,
      overlayClassName: null
    },
    {
      programTitle: "Lansing Marketing Services",
      programSubtitle: "Dedicated to supporting your marketing needs to help you increase profits and ultimately grow your business.",
      programButtonText: "Learn More »",
      programUrl: quickLoginUrl + "?returnUrl=%2Fprograms%2Fmarketingservices",
      programImage: marketingImg,
      overlayClassName: null
    },
    {
      programTitle: "Lansing Green Financing",
      programSubtitle: "Close more sales and grow your business by offering consumer financing options directly to homeowners.",
      programButtonText: "Learn More »",
      programUrl: quickLoginUrl + "?returnUrl=%2Fprograms%2Flansinggreen",
      programImage: greenImg,
      overlayClassName: null
    },
    {
      programTitle: "ClearWin",
      programSubtitle: "Learn how you can use ClearWin to create Harvey Window quotes and place orders.",
      programButtonText: "Learn More »",
      programUrl: quickLoginUrl + "?returnUrl=%2Fprograms%2Fclearwin",
      programImage: clearwinImg,
      overlayClassName: "blue"
    }
  ];

if (!isNEDivision)
  customerPrograms = customerPrograms.filter(x => 
      x.programTitle !== "ProZone" && x.programTitle !== "ClearWin" && (hasImpactRewards || (x.programTitle !== "ProBucks" && x.programTitle !== "Impact Rewards")));

return (
    <Grid container spacing={0}>
        {
            customerPrograms.map(customerProgram => (
                <HomepageCustomerProgram 
                    key={customerProgram.programTitle}
                    programTitle={customerProgram.programTitle} 
                    programSubtitle={customerProgram.programSubtitle}
                    programButtonText={customerProgram.programButtonText}
                    programUrl={customerProgram.programUrl}
                    programImage={customerProgram.programImage}
                    overlayClassName={customerProgram.overlayClassName} />
            ))
        }
    </Grid>
);
};

export default HomepageCustomerPrograms;